<template>
  <div class="game-container">
    <MatchTheCardsGame :imgList="imgList" @changeCardBG="changeCardBG" :titleInfo="titleInfo" />
  </div>
</template>

<script>
import MatchTheCardsGame from "@/components/Course/GamePage/MatchTheCardsGame";
export default {
  data() {
    return {
          titleInfo:{
          pinyin:"jiā",
          hanzi:"家",
          size: 'small',
          zindex: 0
      },
      imgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G29-match-cards/mole.svg"),
          showObj: true,
          cardActive: false,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G29-match-cards/bird.svg"),
          showObj: true,
          cardActive: false,
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G29-match-cards/man.svg"),
          showObj: true,
          cardActive: false,
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G29-match-cards/mole-house.svg"),
          showObj: true,
          cardActive: false,
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G29-match-cards/man-cave.svg"),
          showObj: true,
          cardActive: false,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G29-match-cards/bird-nest.svg"),
          showObj: true,
          cardActive: false,
        },
      ],
    };
  },
  components: {
    MatchTheCardsGame,
  },
  methods: {
    changeCardBG(index, firstIndex, type) {
      this.imgList[index].type = type;
      this.imgList[index].cardActive = false;
      this.imgList[firstIndex].type = type;
      this.imgList[firstIndex].cardActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











