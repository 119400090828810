<template>
  <div class="game-container">
    <ChooseWordsByPicGame
      :trumpetImg="trumpetImg"
      :chooseHanziList="chooseHanziList"
      :optionsList="optionsList"
      :starNum="3"
      :isNeedTips="true"
      :buttonList="buttonList"
    />
  </div>
</template>

<script>
import ChooseWordsByPicGame from "@/components/Course/GamePage/ChooseWordsByPicGame";
export default {
  data() {
    return {
      buttonList: [
        {
          image: require("@/assets/img/01-Menu/menu-1.svg"),
          startNumberRange: 0,
          endNumberRange: 0,
        },
        {
          image: require("@/assets/img/01-Menu/menu-2.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/01-Menu/menu-3.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
      ],
      trumpetImg: require("@/assets/img/16-GAMES/G50-stories/trumpter.svg"),
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-story-words/lesson-09-ai-1.svg`),
          wordsPinyin: "Wǒ ài māma.",
          wordsHanzi: "我爱妈妈。",
          hanziRightList: [
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
            {
              pinyin: "māma",
              hanzi: "妈妈",
              id: 1,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-story-words/lesson-09-ai-2.svg`),
          wordsPinyin: "Wǒ ài bàba.",
          wordsHanzi: "我爱爸爸。",
          hanziRightList: [
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
            {
              pinyin: "bàba",
              hanzi: "爸爸",
              id: 0,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-story-words/lesson-09-ai-3.svg`),
          wordsPinyin: "Wǒ ài wǒ jiā.",
          wordsHanzi: "我爱我家。",
          hanziRightList: [
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 4,
            },
            {
              pinyin: "jiā",
              hanzi: "家",
              id: 2,
            },
          ],
        },
      ],
      optionsList: [
        {
          pinyin: "bàba",
          hanzi: "爸爸",
          id: 0,
        },
        {
          pinyin: "māma",
          hanzi: "妈妈",
          id: 1,
        },
        {
          pinyin: "jiā",
          hanzi: "家",
          id: 2,
        },
        {
          pinyin: "ài",
          hanzi: "爱",
          id: 3,
        },
        {
          pinyin: "wǒ",
          hanzi: "我",
          id: 4,
        },
      ],
    };
  },
  components: {
    ChooseWordsByPicGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
