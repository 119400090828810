<template>
  <div class="game-container">
    <SpeakingByTurntableGame :gameInfo="gameInfo" :optionsList="optionsList" />
  </div>
</template>

<script>
import SpeakingByTurntableGame from "@/components/Course/GamePage/SpeakingByTurntableGame";
export default {
  data() {
    return {
      gameInfo: {
        bgImg: require("@/assets/img/03-Backgrounds/level-1-chapter-2-background-blue.svg"),
        titleHanzi: "我爱……",
        titlePinyin: "Wǒ ài……",
        turntableImg: require("@/assets/img/16-GAMES/G57-turntable/circle.svg"),
        needleImg:require("@/assets/img/16-GAMES/G57-turntable/needle-heart.svg"),
      },
      optionsList: [
        {
          pinyin: "māma",
          hanzi: "妈妈",
          index: 2,
        },
        {
          pinyin: "lǎoshī",
          hanzi: "老师",
          index: 1,
        },
        {
          pinyin: "nǐ",
          hanzi: "你",
          index: 0,
        },
        {
          pinyin: "bàba",
          hanzi: "爸爸",
          index: 3,
        },
      ],
    };
  },
  components: {
    SpeakingByTurntableGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











