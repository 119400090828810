<template>
  <div class="game-container">
    <RecognitionPinyinGame :optionList="optionList" :imgList="imgList" :backgroundSize="`cover`" :isFullScreen="true" />
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      optionList: [
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/1-mama.svg"),
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/2-baba.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/3-mama.svg"),
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/4-baba.svg"),
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/5-baba.svg"),
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/6-baba.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/7-mama.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/8-mama.svg"),
        },
      ],

      imgList: [
        {
          id: 1,
          pinying: "bàba",
          hanzi: "爸爸",
        },
        {
          id: 2,
          pinying: "māma",
          hanzi: "妈妈",
        },
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











